import { ChangeDetectionStrategy, Component } from '@angular/core'
import { AppLayoutComponent } from '../app/layouts/app-layout.component'
import { RouterOutlet } from '@angular/router'

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AppLayoutComponent, RouterOutlet],
  selector: 'app-root',
  templateUrl: 'app.page.html',
  styleUrl: 'app.page.css',
})
export class AppPage {}
