import { bootstrapApplication } from '@angular/platform-browser'
import { appConfig } from './pages/app.config'
import { AppPage } from './pages/app.page'
import vars from '@server/shared/vars'
import { enableProdMode, isDevMode } from '@angular/core'

bootstrapApplication(AppPage, appConfig).catch(err => console.error(err))

if (vars.isProduction) enableProdMode()

console.log('Is Server: ' + vars.isServer)
console.log('Is Production: ' + vars.isProduction)
console.log('Angular.isDevMode: ' + isDevMode())
