import { Route } from '@angular/router'

export const appRoutes: Route[] = [
  {
    path: 'login',
    loadComponent: () => import('./public/login/login.page').then(c => c.LoginPage),
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin-app.page').then(c => c.AdminAppPage),
    children: [
      {
        path: 'one',
        loadComponent: () => import('./admin/one/one.page').then(c => c.OnePage),
      },
      {
        path: 'two',
        loadComponent: () => import('./admin/two/two.page').then(c => c.TwoPage),
      },
    ],
  },
]
